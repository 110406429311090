import React from "react"
import Img from "gatsby-image"
import styled from "@emotion/styled"

const components = {
  singleColumnImage: singleColumnImage,
  singleColumnText: singleColumnText,
  doubleColumnImage: doubleColumnImage,
  doubleColumnText: doubleColumnText,
  keyboards: keyboards,
  divider: divider,
}

// Uses: One Column/Full Width Image
function singleColumnImage({ section }) {
  const alt = section.image[0].title
  const fullImage = section.imageFile[0]

  const UseSingle = styled.section`
    margin-bottom: 2.5rem;

    @media (max-width: 46rem) {
      padding-left: 0rem;
      padding-right: 0rem;
    }

    @media (min-width: 46rem) {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
      max-width: 68rem;
    }
  `

  return (
    <UseSingle>
      <Img fluid={fullImage.childImageSharp.fluid} alt={alt} title={alt} />
    </UseSingle>
  )
}

// Uses: One Column Text
function singleColumnText({ section }) {
  const text = section.text

  const UseSingleText = styled.section`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(6, 1fr);
    gap: 2.5rem;
    margin-bottom: 2.5rem;

    @media (min-width: 46rem) {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
      max-width: 58rem;
    }

    div {
      grid-column-end: span 6;

      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  `

  return (
    <UseSingleText>
      <div dangerouslySetInnerHTML={{ __html: text }} />
    </UseSingleText>
  )
}

// Uses: Divider
function divider({ section }) {
  const Divider = styled.section`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(6, 1fr);
    margin-top: 4.5rem;
    margin-bottom: 4.5rem;
    max-width: 38rem;
    background-color: ${props => props.theme.primaryYellow};
    height: 0.35rem;

    @media (max-width: 46rem) {
      margin-top: 2.75rem;
      margin-bottom: 2.75rem;
      max-width: 13rem;
    }
  `

  return <Divider />
}

// Uses: Two Columns/Half Width Image(s)
function doubleColumnImage({ section }) {
  const leftImage = section.imageFile[0]
  const leftAlt = section.image[0].title
  const rightImage = section.imageFile[1]
  const rightAlt = section.image[1].title

  const UsesDouble = styled.section`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(6, 1fr);
    gap: 2.5rem;
    margin-bottom: 2.5rem;

    @media (max-width: 46rem) {
      padding-left: 0rem;
      padding-right: 0rem;
    }

    @media (min-width: 46rem) {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }

    .gatsby-image-wrapper {
      grid-column-end: span 6;

      img {
        background-color: rgb(240, 240, 240);
      }

      @media (min-width: 46rem) {
        grid-column-end: span 3;
      }
    }
  `

  return (
    <UsesDouble>
      <Img
        data-sal="slide-up"
        data-sal-delay="0"
        data-sal-duration="450"
        data-sal-easing="ease-in-out"
        fluid={leftImage.childImageSharp.fluid}
        alt={leftAlt}
        title={leftAlt}
      />

      <Img
        data-sal="slide-up"
        data-sal-delay="200"
        data-sal-duration="250"
        data-sal-easing="ease-in-out"
        fluid={rightImage.childImageSharp.fluid}
        alt={rightAlt}
        title={rightAlt}
      />
    </UsesDouble>
  )
}

// Uses: Two Columns/Half Width Image(s)
function doubleColumnText({ section }) {
  const doubleTitle = section.sectionTitle
  const leftText = section.text
  const rightText = section.rightText

  const UsesDouble = styled.section`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(6, 1fr);
    gap: 1rem 3.5rem;
    margin-bottom: 2.5rem;

    @media (min-width: 46rem) {
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }

    h2 {
      grid-column-end: span 6;
    }

    div {
      grid-column-end: span 6;

      @media (min-width: 46rem) {
        grid-column-end: span 3;
      }

      p {
        &:last-of-type {
          margin-bottom: 0;
        }
      }

      ul {
        ul {
          margin-top: 0.5rem;
          margin-bottom: 0.75rem;
          padding-top: 0;
          padding-bottom: 0;
          line-height: 0.8rem;
          opacity: 0.7;

          li {
            font-size: 0.93rem;
          }
        }
      }
    }
  `

  return (
    <UsesDouble>
      <h2>{doubleTitle}</h2>
      <div dangerouslySetInnerHTML={{ __html: leftText }} />

      <div dangerouslySetInnerHTML={{ __html: rightText }} />
    </UsesDouble>
  )
}

// Uses: Keyboards
function keyboards({ section }) {
  const keyboardsList = section.imageFile

  const UsesKeyboards = styled.section`
    display: grid;
    grid-auto-flow: dense;
    grid-template-columns: repeat(6, 1fr);
    margin-bottom: 2.5rem;

    @media (min-width: 46rem) {
      gap: 2rem 4rem;
      margin-top: 2.5rem;
      margin-bottom: 2.5rem;
    }

    @media (max-width: 46rem) {
      padding-left: 0;
      padding-right: 0;
    }

    h2 {
      grid-column-end: span 6;

      @media (max-width: 46rem) {
        padding-left: 1.4rem !important;
        padding-right: 1.4rem !important;
      }

      @media (min-width: 46rem) {
        margin-bottom: 0;
      }
    }
  `

  const UsesKeeb = styled.div`
    grid-column-end: span 6;

    @media (max-width: 46rem) {
      padding-left: 0;
      padding-right: 0;
      margin-bottom: 3rem;

      &:last-of-type {
        margin-bottom: 0;
      }

      h3,
      p,
      ul {
        padding-left: 1.4rem !important;
        padding-right: 1.4rem !important;
      }
    }

    @media (min-width: 46rem) {
      grid-column-end: span 2;

      &:nth-of-type(1),
      &:nth-of-type(2) {
        grid-column-end: span 3;
      }
    }

    h3 {
      font-size: 2.6rem;
      margin: 1rem 0;
      padding: 0;
    }

    ul {
      margin-bottom: 0;

      li {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  `

  const KeebContent = styled.div`
    grid-column-end: span 4;
  `

  return (
    <UsesKeyboards>
      <h2 id="keyboards">Keyboards</h2>
      {keyboardsList.map((keeb, i) => (
        <UsesKeeb key={i}>
          <Img
            fluid={keeb.childImageSharp.fluid}
            alt="Keyboard"
            title="Keyboard"
          />
          <KeebContent>
            <h3>{section.image[i].title}</h3>
            {(() => {
              switch (i) {
                case 0:
                  return (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: section.ut472Description,
                      }}
                    />
                  )
                case 1:
                  return (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: section.roproDescription,
                      }}
                    />
                  )
                case 2:
                  return (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: section.dz60Description,
                      }}
                    />
                  )
                case 3:
                  return (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: section.mf82Description,
                      }}
                    />
                  )
                case 4:
                  return (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: section.mf68Description,
                      }}
                    />
                  )
                default:
                  return (
                    <div>
                      <p>Coming Soon...</p>
                    </div>
                  )
              }
            })()}
          </KeebContent>
        </UsesKeeb>
      ))}
    </UsesKeyboards>
  )
}

export default function UsesSection({ section }) {
  const ComponentName = components[section.typeHandle]

  return <ComponentName section={section} />
}
