import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import styled from "@emotion/styled"
import SEO from "../components/seo/seo"
import Layout from "../components/layout"
import UsesSection from "../components/uses/builder"
import { getPrettyDate, getStandardDate } from "../utils/dates"

const UsesPage = () => {
  const data = useStaticQuery(graphql`
    query Uses {
      site {
        siteMetadata {
          siteUrl
        }
      }
      craft {
        uses: entries(title: "Uses") {
          dateCreated
          dateUpdated
          ... on Craft_uses_uses_Entry {
            image: seoImage {
              title
              url(width: 720, height: 475, quality: 100, immediately: true)
            }
            imageFile {
              childImageSharp {
                fixed(width: 600, quality: 90) {
                  src
                }
              }
            }
            seoTitle
            seoDescription
            introduction
            usesContent {
              ... on Craft_usesContent_singleColumnImage_BlockType {
                typeHandle
                image {
                  title
                  url(width: 1500, quality: 100, immediately: true)
                }
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 1450, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              ... on Craft_usesContent_singleColumnText_BlockType {
                typeHandle
                text
              }
              ... on Craft_usesContent_keyboards_BlockType {
                typeHandle
                ut472Description
                roproDescription
                dz60Description
                mf82Description
                mf68Description
                image {
                  title
                  url(width: 700, quality: 100, immediately: true)
                }
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 750, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              ... on Craft_usesContent_doubleColumnImage_BlockType {
                typeHandle
                image {
                  title
                  url(width: 800, quality: 100, immediately: true)
                }
                imageFile {
                  childImageSharp {
                    fluid(maxWidth: 750, quality: 100) {
                      ...GatsbyImageSharpFluid_withWebp_noBase64
                    }
                  }
                }
              }
              ... on Craft_usesContent_doubleColumnText_BlockType {
                typeHandle
                sectionTitle
                text
                rightText
              }
              ... on Craft_usesContent_divider_BlockType {
                typeHandle
                icon
              }
            }
          }
        }
      }
    }
  `)

  const seoTitle = data.craft.uses[0].seoTitle
  const seoDescription = data.craft.uses[0].seoDescription
  const seoImage =
    data.site.siteMetadata.siteUrl +
    data.craft.uses[0].imageFile[0].childImageSharp.fixed.src

  const usesContent = data.craft.uses[0].usesContent

  const dateUpdated = data.craft.uses[0].dateUpdated

  const UsesContainer = styled.div`
    padding-top: 6.5rem;
  `

  const UsesIntro = styled.section`
    max-width: 58rem;
  `

  const UsesContent = styled.div`
    section {
      &:last-of-type {
        margin-bottom: 4rem;
      }
    }
  `

  const UsesTime = styled.p`
    font-size: 0.8rem;
    color: ${props => props.theme.grays.mid};
    margin: 0 0 1rem;
    padding: 0;

    @media (min-width: 46rem) {
      margin: 0 0 3rem;
    }
  `

  const UsesTitle = styled.h1`
    margin-bottom: 0;
    font-size: 2.75rem;

    &:after {
      content: "";
      margin: 4rem 0 2rem;
      width: 3rem;
      height: 0.36rem;
      background-color: ${props => props.theme.primaryYellow};
      display: block;
      position: relative;
      left: -1.5rem;
    }
  `

  return (
    <Layout>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage}
        pathname={`uses`}
        website
      />
      <UsesContainer>
        <UsesIntro>
          <UsesTime>
            Last Updated:{" "}
            <time dateTime={getStandardDate(dateUpdated)}>
              {getPrettyDate(dateUpdated)}
            </time>
          </UsesTime>

          <UsesTitle>Uses</UsesTitle>
          <div
            dangerouslySetInnerHTML={{
              __html: data.craft.uses[0].introduction,
            }}
          />
        </UsesIntro>

        <UsesContent>
          {usesContent.map((section, index) => (
            <UsesSection key={index} section={section} />
          ))}
        </UsesContent>
      </UsesContainer>
    </Layout>
  )
}

export default UsesPage
